@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-4-slide-content {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }

  &__page-title-wrapper {
    position: absolute;
    top: 120px;
    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: 100px;
    //  left: 39%;
    //  width: 80px;
    //  transform: rotate(90deg);
    //  border-bottom: 6px solid $light-black;
    //}
  }

  &__page-title {
    text-transform: uppercase;
    font-size: 1em;

    @include tablet-medium {
      font-size: 1.7em;
    }
    @include desktop {
      font-size: 2.2em;
    }
    @include desktop-large {
      font-size: 3em;
    }

    &--white {
      color: $white;
    }

    &--black {
      color: $light-black;
    }
  }

  &__section {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;

    &--1 {
      background-image: url("../../../../../../assets/img/left_partners_background.png");
      background-position: right;
      filter: grayscale(20%);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &--2 {
      background-color: $white;
    }

    &--3 {
      background-image: url("../../../../../../assets/img/right_partners_background.png");
      background-position: left;
      filter: grayscale(20%);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &--white {
      color: $white;

      .r4z-4-slide-content-section {
        &__title-wrapper {
          border-bottom: 4px solid $white;
        }
      }
    }

    &--black {
      color: $light-black;

      .r4z-4-slide-content-section {
        &__title-wrapper {
          border-bottom: 4px solid $light-black;
        }
      }
    }
  }
}
.active {
  .r4z-4-slide-content {
    &__page-title {
      animation: slideInTopSlowIn 2000ms cubic-bezier(0, 1, 0.2, 1);
    }
    &__page-title-wrapper {
      &:after {
        animation: fadeInSlowIn 1800ms ease-in;
      }
    }
    &__section {
      &--1 {
        .r4z-4-slide-content-section {
          &__wrapper {
            animation: slideInBottomIn 1000ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 800ms ease-in;
          }
        }
      }

      &--2 {
        .r4z-4-slide-content-section {
          &__wrapper {
            animation: slideInBottomIn 1500ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 1200ms ease-in;
          }
        }
      }

      &--3 {
        .r4z-4-slide-content-section {
          &__wrapper {
            animation: slideInBottomIn 2000ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 1500ms ease-in;
          }
        }
      }
    }
  }
}


.r4z-4-slide-content-section {
  &__wrapper {
    min-height: 35vh;
    margin-top: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  &__title-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    @include tablet-medium {
      font-size: 1.4em;
    }
    @include desktop {
      min-height: 80px;
      font-size: 1.8em;
    }
    @include desktop-large {
      font-size: 2.2em;
    }
  }

  &__text-wrapper {
    padding: 0 20px;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    line-height: 1.2em;
    font-weight: 600;
    text-align: center;
    font-size: 0.8em;

    @include tablet-medium {
      font-size: 1em;
    }
    @include desktop {
      font-size: 1.2em;
    }
  }

  &__icon {
    margin-bottom: 50px;
    width: 60px;
    z-index: 1;

    img {
      width: 100%;
      height: 80px;
    }
  }
}