@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-product-preview-content {
  &__wrapper {
    width: 100%;
    height: 90%;
    display: flex;
    background-image: url("../../../../../../assets/img/leafs-plant.png");
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    @include desktop {

    }
    @include desktop-large {
      flex-direction: row;
    }
  }

  &__title-wrapper {
    width: 100%;
    height: 100%;
    padding: 20vh 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    @include desktop-large {
      width: 40%;
      padding: 0 20px 0 10%;
    }
  }

  &__pre-title-text {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
  }

  &__title-text {
    margin-top: 25px;
    width: 100%;
    font-size: 2.8em;
    text-align: center;
    font-weight: 600;
  }

  &__product-hero-image-wrapper {
    width: 100%;
    max-width: 500px;
    margin: auto;
    @include desktop-large {
      width: auto;
      max-width: unset;
      margin: 0;
    }
  }

  &__product-hero-image-container {
    max-width: 95%;
    margin: auto;
    display: flex;
    align-items: flex-end;
    @include desktop-large {
      max-width: 95%;
    }
  }

  &__product-hero-image {
    width: 100%;
    margin: 10% auto 0;
  }

  &__button-wrapper {
    margin-top: 20px;
  }

  &__button {
    height: 48px;
    width: 290px;
    border-radius: 9px;
    border: 1px solid #199393;
    background-color: #199393;
    color: $white;
    font-weight: 500;

    &:hover {
      border: 1px solid $white;
      background-color: transparent;
    }
  }
}

.r4z-product-preview-content__wrapper.active {
  animation: fadeIn 1500ms ease-in;
}
