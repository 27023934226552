@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-request-demo-modal {
  &__container {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__text {
    font-size: 1.2em;
  }
}

.r4z-request-demo-form {
  &__wrapper {
    width: 80%;
    margin-top: 20px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
  }

  &__input-field {
    margin: 5px;
    padding-bottom: 5px;
    border: none;
    font-size: 1.3em;
    font-family: $font-global;
    background-color: transparent;
    text-align: left;
    color: $dark-green;
    @include tablet-medium {
      font-size: 1.5em;
    }

    &:focus {
      outline: none;
    }
  }

  &__input-field-border {
    border-top: 1px solid $light-black;
  }

  &__button-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
