@import "../../../assets/styles/variables";

.r4z-login-page {
  &__form-wrapper {
    max-width: 600px;
    width: 90%;
    position: relative;
    margin: 200px auto 20px;
    border: 1px solid $grey-transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form-title {
    padding: 5px;
    text-align: center;
  }

  &__form-logo {
    width: 70px;
    margin: 20px auto;

  }
}
