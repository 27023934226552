.r4z-link {
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  &:hover {

  }

  &:active {

  }

  &:visited {

  }

  &:focus {

  }
}