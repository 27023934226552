@import "../../../assets/styles/variables";

.r4z-login-form {
  &__wrapper {
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 2em 2em 1em;
    box-sizing: border-box;
  }

  &__field-wrapper {
    padding-bottom: 1.5em;
  }

  &__button {
    height: 58px;
    width: 100%;
    background-color: $dark-green;
    color: white;
  }
}
