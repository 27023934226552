$mq-xsmall: '(min-width: 23em)';    // 384px / base value of 16px
$mq-small: '(min-width: 31em)';     // 496px / base value of 16px
$mq-medium: '(min-width: 45em)';    // 720px / base value of 16px
$mq-large: '(min-width: 60em)';     // 960px / base value of 16px
$mq-xlarge: '(min-width: 75em)';    // 1200px / base value of 16px

// from 384px
@mixin mobile {
  @media #{$mq-xsmall} {
    @content;
  }
}

// from 496px
@mixin tablet-small {
  @media #{$mq-small} {
    @content;
  }
}

// from 720px
@mixin tablet-medium {
  @media #{$mq-medium} {
    @content;
  }
}

// from 960px
@mixin desktop {
  @media #{$mq-large} {
    @content;
  }
}

// from 1200px
@mixin desktop-large {
  @media #{$mq-xlarge} {
    @content;
  }
}

