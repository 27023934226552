@import "../../../assets/styles/variables";

.r4z-modal {
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.58);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__wrapper {
    position: relative;
    margin: 15vh auto;
    max-height: 98%;
    //transition: all 0.5s;
  }
  &--open {
    animation: modalAnimationOpen 500ms;
  }

  &--close {
    animation: modalAnimationClose 500ms;
  }
  &__container {
    position: relative;
    margin: auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    z-index: 1051;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  }

  &__header {
    display: flex;
    align-items: center;
    min-height: 20px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;

    .r4z-modal-header {
      &__title-wrapper {
        flex: 1;
      }

      &__title {
        color: $light-black;
      }

      &__x-button {
        float: right;
        font-size: 21px;
        font-weight: bold;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=50);
        opacity: .7;
        cursor: pointer;
        background-color: #fff;
        color: $dark-green;
        border: 1px solid $dark-green;
        border-radius: 5px;

        &:hover, &:focus {
          background-color: $dark-green;
          border: 1px solid transparent;
          color: #fff;
          outline: none;
        }

        &:active {
          transform: scale(1.1);
        }
      }
    }
  }

  &__body {
    padding: 10px;
  }

  &__footer {
    padding: 10px 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;

    .r4z-modal-footer {
      &__button {
        margin-left: 10px;
        display: inline-block;
        padding: 8px 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: $dark-green;
        color: $white;
        text-transform: uppercase;
        font-size: 15px;
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 9px;

        &--ok {
          &:hover, &:focus {
            background-color: $white;
            border: 1px solid $dark-green;
            color: $dark-green;
          }
        }

        &--close {
          background-color: #fff;
          color: $dark-green;
          border: 1px solid $dark-green;

          &:hover, &:focus {
            background-color: $dark-green;
            border: 1px solid transparent;
            color: $white;
            outline: none;
          }

          &:active {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@keyframes modalAnimationOpen {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes modalAnimationClose {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}

