@import "../../../assets/styles/variables";

.r4z-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  cursor: pointer;
  border-radius: $layout-border;
  font-family: inherit;
  font-size: inherit;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:active {
    transform: scale(1.02);
  }

  &--white {
    border-color: #ffffff;
    background-color: #ffffff;
    color: $light-black;

    &:hover {
      color: #ffffff;
      background-color: transparent;
    }
  }

  &--black {
    border-color: $light-black;
    background-color: $light-black;
    color: $white;

    &:hover {
      color: $light-black;
      background-color: transparent;
    }
  }

  &--white-transparent {
    border-color: $white;
    color: $white;
    background-color: transparent;

    &:hover {
      background-color: $white;
      color: $light-black;

    }
  }

  &--black-transparent {
    border-color: $black;
    background-color: transparent;
    color: $light-black;

    &:hover {
      color: $white;
      background-color: $black;

    }
  }

  &__text {
    padding: 0 1rem;
    border: none;
    font-size: inherit;
  }
}
