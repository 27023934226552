@import "../../../assets/styles/variables";

.r4z-scroll-down-button {
  &__image {
    display: block;
    width: 100%;
    margin: auto;

    &:hover {
      cursor: pointer;
    }

    &:active {
      max-width: 50px;
    }

    &--animation {
      animation: bounce 2s linear infinite;
    }

    &:hover {
      animation: unset;
    }
  }

  &__text {
    text-align: center;
    &--white {
      color: $white;
    }
    &--black {
      color: $black;
    }
    &--lightBlack {
      color: $light-black;
    }
  }
}

@keyframes bounce {
  20%, 50%, 80%, to {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
}