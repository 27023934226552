.r4z-1-slide-content-mobile {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 350px;
  margin-top: 50px;
  background-image: url("../../../../../../assets/img/6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20% 0;
  background-color: rgba(0, 0, 0, 0.2);
  filter: grayscale(20%);

  &:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .r4z-1-slide-content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;

    &__text-wrapper {
      position: relative;
      top: 40%;
      transform: translateY(-50%);
      text-align: center;
    }
    &__title {
      font-size: 9vw;
      line-height: 1.3em;
      span {
        font-size: 6vw;
      }
    }
    &__text {
      padding-top: 20px;
      font-size: 5.5vw;
      line-height: 1.3em;
    }
  }
  &__scroll-down-button-wrapper {
    position: absolute;
    bottom: 60px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }
}