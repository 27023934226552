@import '../../../assets/styles/variables';
@import '../../../assets/styles/breakpoints';

.r4z-content-section-hero-banner {
  &--withAuthor {
    padding-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__title {
    font-size: 5vw;
    font-weight: 500;
    max-width: 650px;
    color: $white;
    line-height: 1.3em;
    text-align: center;
    text-transform: uppercase;
    @include tablet-small {
      font-size: 2em;
    }
  }

  &__border-line {
    height: 2px;
    max-width: 400px;
    width: 60%;
    border-bottom: 2px solid $white;
  }

  &__text {
    max-width: 500px;
    padding: 40px 1%;
    color: $white;
    text-align: center;
    line-height: 1.3em;

  }

  &__button {
    height: 48px;
    padding: 10px 40px;
    border-radius: $layout-border;
  }

  &__author-wrapper {
    height: 100%;
    padding: 10px 10px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__author {
    font-weight: 400;
    font-size: 0.8em;
  }
}
