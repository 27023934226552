.r4z-terms-of-use {
  min-height: 101vh;
  &__wrapper {
    height: 20vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-image: url("../../../assets/img/about-us-banner.png");
    &:before {
      background: rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
}
