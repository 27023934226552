@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-side-menu {
  padding: 40px 0;

  &__link-wrapper {
    min-height: 60px;
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $grey;

    &:hover {
      cursor: pointer;

      a {
        color: $dark-green;
      }
    }

    &:active {
      background-color: $dark-green;

      a {
        color: $white;
      }
    }
  }

  &__link {
    color: $grey;
    font-size: 1.5em;

    &--active {
      color: $dark-green;
      font-weight: bold;
    }
  }

  &__logo-wrapper {
    margin: -37px 9px -12px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
