.flash-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 20px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.flash-message div.flash-message-inner {
  padding: 8px;
}

.flash-message-inner:first-child {
  margin-top: 8px;
}

.flash-message-inner-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  pointer-events: all;
}

.flash-message-inner-content p {
  margin: 0;
  padding: 3px;
}

.flash-message .icon {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.flash-message-success .icon {
  color: #52c41a;
}

.flash-message-inner.remove-animation {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.flash-message-inner.appear-animation {
  overflow: auto;
  -webkit-animation-name: MessageMoveIn;
  animation-name: MessageMoveIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@-webkit-keyframes MessageMoveIn {
  0% {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
  100% {
    padding: 8px;
    max-height: 150px;
    opacity: 1;
  }
}

@keyframes MessageMoveIn {
  0% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
  100% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
}