@import "mixins";

// Application width sizes
$min-screen-size: 320px;
$max-screen-size: 1240px;
$max-content-size: 960px;

// Fonts
$font-global: 'Josefin Sans', sans-serif;
$logo-font: sans-serif;

// White
$white: #ffffff;

// Black
$black: #000000;
$lighter-black: #0e0e0e;
$light-black: #393c41;

// Grey
$light-grey: #EAEAEA;
$grey: #878787;
$grey-dark: #999999;
$grey-light: #B3B3B3;
$grey-transparent: #0000001f;

//$dark-green: #344e47;
$dark-green: #4e6d65;
$green: #325549;
$dark-green-dimmed: #4e6d65b8;

// Red
$red-light: rgb(246, 79, 100);

// Element colors
$text-color: $light-black;

// Application Theme params
$layout-border: 9px;

// Animations
@include animationEffect('fadeIn', 'opacity', 0, 1);
@include animationEffect('slideInLeft', 'transform', 'translate3d(-100%, 0, 0)', 'translate3d(0, 0, 0)');

@keyframes fadeInSlowIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeftSlowIn {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInTopSlowIn {
  0% {
    transform: translate(0, -400px);
  }
  30% {
    transform: translate(0, -400px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideInBottomIn {
  0% {
    transform: translate(0, 100vh);
  }
  30% {
    transform: translate(0, 100vh);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideInBottomSlowIn {
  0% {
    top: 100vh;
  }
  30% {
    top: 100vh;
  }
  to {
    top: 50%;
  }
}
