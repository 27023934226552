@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-2-slide-content-mobile {
  &__page-title-wrapper {
    position: relative;
    margin-top: 50px;
  }

  &__page-title {
    font-size: 6vw;
    text-align: center;
    text-transform: uppercase;
    color: $dark-green;
  }

  &__page-title-after {
    margin-top: 10px;
    height: 60px;
    width: calc(50% - 2px);
    border-right: 4px solid $dark-green;
  }
}

.r4z-2-slide-content-section-mobile {
  &__wrapper {
    margin: 20px 0;
    padding: 40px 0;
    text-align: center;
    &--black {
      color: $light-black;
    }
    &--white {
      color: $white;
    }
    &--1 {
      margin: 10px 0;
    }
    &--2 {
      background-image: url("../../../../../../assets/img/steps_background.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__step-number {
    font-size: 7vw;
    font-weight: bold;
    @include mobile {
      font-size: 5vw;
    }
  }
  &__title-wrapper {
    p {
      padding: 10px 0;
      font-size: 4vw;
      @include mobile {
        font-size: 3vw;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    font-size: 8vw;
    @include mobile {
      font-size: 6vw;
    }
  }
  &__text-wrapper {
    padding: 20px 15vw;
  }

  &__text {
    font-size: 4.5vw;
    line-height: 1.2em;
    @include tablet-small {
      font-size: 3.5vw;
      line-height: 1em;
    }
  }
  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 50px;
    }
  }
}
