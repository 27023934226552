@import "../../../../../../assets/styles/variables";

.r4z-product-preview-content-mobile {
  &__wrapper {
    background-image: url("../../../../../../assets/img/leafs-plant.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__title-wrapper {
    height: 45vh;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  &__pre-title-text {
    padding: 30px 0;
  }

  &__title-text {
    padding: 10px 0;
    font-size: 2.5em;
    text-align: center;
  }

  &__product-hero-image-wrapper {
    height: 50vh;
    display: flex;
    justify-content: center;
  }
  &__product-hero-image {
    height: 40vh;
  }
}
