@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-5-slide-content {
  &__wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__header {
    width: 100%;
    max-width: 600px;
    margin-bottom: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__header-image {
    &--animation {
      animation:  fadeInSlowIn 1300ms ease-in, slideInLeftSlowIn 1200ms cubic-bezier(0, 1, 0.2, 1);
    }
  }

  &__text-wrapper {
    max-width: 840px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    @include tablet-medium {
      padding: 0 40px;
      font-size: 0.8em;
    }

    @include desktop {
      padding: 0 20px;
      font-size: 1em;
    }


    &--animation {
      animation: fadeInSlowIn 1200ms ease-in;
    }
  }

  &__text {
    font-size: 2.5em;
    text-align: center;
  }

  &__button-wrapper {
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    height: 48px;
    color: $white;
    padding-right: 50px;
    padding-left: 50px;
    background-color: $dark-green;
    border-radius: $layout-border;
    &:hover {
      background-color: transparent;
      color: $dark-green;
    }
  }
}
