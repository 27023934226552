@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-2-slide-content {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }

  &__page-title-wrapper {
    position: absolute;
    top: 100px;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: 100px;
    //  width: 80px;
    //  border-bottom: 6px solid $white;
    //  transform: rotate(90deg);
    //  @include tablet-medium {
    //    left: 33%;
    //  }
    //}
  }

  &__page-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 1em;

    @include tablet-medium {
      font-size: 1.7em;
    }
    @include desktop {
      font-size: 2.2em;
    }
    @include desktop-large {
      font-size: 2.5em;
    }

    &--white {
      color: $white;
    }

    &--black {
      color: $black;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;

    &--1 {
      background-color: $white;
    }

    &--2 {
      position: relative;
      background-image: url("../../../../../../assets/img/steps_background.png");
      background-repeat: no-repeat;
      background-size: cover;
      filter: grayscale(20%);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &--3 {
      background-color: $white;
    }

    &--white {
      color: $white;

      .r4z-4-slide-content-section {
        &__title-wrapper {
          border-bottom: 4px solid $white;
        }
      }
    }

    &--black {
      color: $light-black;

      .r4z-4-slide-content-section {
        &__title-wrapper {
          border-bottom: 4px solid $black;
        }
      }
    }
  }
}

.active {
  .r4z-2-slide-content {
    &__page-title {
      animation: slideInTopSlowIn 2000ms cubic-bezier(0, 1, 0.2, 1);
    }

    &__page-title-wrapper {
      &:after {
        animation: fadeInSlowIn 2000ms ease-in;
      }
    }

    &__section {
      &--1 {
        .r4z-2-slide-content-section {
          &__wrapper {
            animation: slideInBottomSlowIn 1300ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 1500ms ease-in;
          }
        }
      }

      &--2 {
        .r4z-2-slide-content-section {
          &__wrapper {
            animation: slideInBottomSlowIn 1500ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 1800ms ease-in;
          }
        }
      }

      &--3 {
        .r4z-2-slide-content-section {
          &__wrapper {
            animation: slideInBottomSlowIn 2000ms cubic-bezier(0, 1, 0.2, 1);
          }

          &__icon {
            animation: fadeInSlowIn 2000ms ease-in;
          }
        }
      }

      .r4z-2-slide-content-section {
        &__button {
          animation: fadeInSlowIn 2000ms ease-in;
        }
      }
    }
  }
}


.r4z-2-slide-content-section {
  &__wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50vh);
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    font-size: 2.3em;
    font-weight: 700;
  }

  &__title-wrapper {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    font-weight: 600;
    box-sizing: border-box;
    p {
      font-size: 0.5em;
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__text {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 500;
    text-align: center;
  }

  &__icon {
    position: absolute;
    margin-bottom: 10vh;
    bottom: 12vh;
    width: 60px;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  &__button {
    position: absolute;
    bottom: 10vh;
    padding-left: 50px;
    padding-right: 50px;
    border-color: $white;
    z-index: 1;

    &:hover {
      color: $white;
      background-color: transparent;
    }
  }
}