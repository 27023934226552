@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-6-slide-content {
  position: relative;
  margin: auto;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EAEAEA;
  @include tablet-medium {
    padding: 0;
    background-color: transparent;
  }

  &__wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;

    &--animation {
      animation: fadeInSlowIn 1200ms ease-in;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__title {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 7vw;
    color: $light-black;
    text-transform: uppercase;
    @include tablet-medium {
      font-size: 3em;
    }
  }

  &__text {
    font-size: 2vw;
    color: $light-black;
    @include tablet-medium {
      font-size: 2em;
    }
  }
}

.r4z-6-slide-content-form {
  &__wrapper {
    margin-top: 20px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
  }

  &__input-field {
    margin: 5px;
    padding-bottom: 5px;
    border: none;
    font-size: 1.5em;
    font-family: $font-global;
    background-color: transparent;
    text-align: center;
    @include tablet-medium {
      font-size: 2em;
      text-align: left;
    }

    &:focus {
      outline: none;
    }
  }

  &__input-field-border {
    border-top: 2px solid $light-black;
  }

  &__button-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__submit-button {
    height: 48px;
    padding: 5px 30px;
    background-color: $dark-green;
    color: $white;
    border-color: $dark-green;
    border-radius: $layout-border;
    @include tablet-medium {
      padding: 12px 50px;
    }
    &:hover {
      color: $dark-green;
      background-color: transparent;
    }
  }
}
