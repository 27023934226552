@import "../../../assets/styles/variables";

.r4z-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: " ";
    display: block;
    margin: 0 4px;
    border-radius: 50%;
    animation: r4z-loader-animation 1.2s linear infinite;
  }

  &--small {
    //width: 58px;
    //height: 58px;

    &:after {
      width: 20px;
      height: 20px;
      border: 5px solid;
    }
  }

  &--normal {
    width: 60px;
    height: 60px;

    &:after {
      width: 30px;
      height: 30px;
      border: 8px solid;
    }
  }

  &--large {
    width: 100px;
    height: 100px;

    &:after {
      width: 65px;
      height: 65px;
      border: 15px solid;
    }
  }

  &--grey {
    &:after {
      border-color: #d8d8d8 transparent #d8d8d8 transparent;
    }
  }

  &--black {
    &:after {
      border-color: #000 transparent #000 transparent;
    }
  }

  &--white {
    &:after {
      border-color: #fff transparent #fff transparent;
    }
  }

  &--white-transparent {
    &:after {
      border-color: rgba(255,255,255, 0.8) transparent rgba(255,255,255, 0.8) transparent;
    }
  }
}

@keyframes r4z-loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
