@import "../../../assets/styles/variables";

.r4z-slide-navigator {
  &__wrapper {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &__dot {
    //border: 1px solid #121212;
    border-radius: 50%;

    width: 13px;
    height: 13px;
    display: block;
    margin-top: 20px;
    transition: transform 0.6s ease, background-color 0.6s ease;

    &:hover {
      cursor: pointer;
    }

    &--active {

      transform: scale(1.5);
    }

    &--white {
      background-color: rgba(255, 255, 255, 0.6);

      &.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }

    &--black {
      //background-color: rgba(0, 0, 0, 0.6);
      background-color: $dark-green-dimmed;

      &.active {
        //background-color: rgba(0, 0, 0, 0.9);
        background-color: $dark-green;
      }
    }
  }
}