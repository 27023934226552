@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/breakpoints';

.r4z-5-slide-content-mobile {
  padding-bottom: 40px;
  &__image-wrapper {
    padding: 10vw 0;
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 80%;
  }

  &__text-wrapper {
    padding: 2vw 10vw;
    @include tablet-small {
      padding: 2vw 15vw;
    }
  }

  &__text {
    font-size: 4.5vw;
    text-align: center;
    line-height: 1.2em;
    @include tablet-small {
      font-size: 3vw;
      line-height: 1em;
    }
  }

  &__button-wrapper {
    padding: 2vw 0;
    display: flex;
    justify-content: center;
  }

  &__button {
    color: $white;
    background-color: $dark-green;
    padding-right: 30px;
    padding-left: 30px;
    &:hover {
      background-color: transparent;
      color: $dark-green;
    }
  }
}
