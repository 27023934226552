@import "variables";

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 16px;
    font-family: $font-global;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: $font-global;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

a {
    color: inherit;
    text-decoration: unset;
    cursor: pointer;
}
