@import "../../../assets/styles/variables";

.r4z-about-page {
  &__header-wrapper {
    height: 30vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    //background-image: url("../../assets/img/about_us_page_banner_leaf.png");
    &:before {
      background: rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  &__title-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
  }

  &__title {
    margin-top: 20px;
    padding: 0 50px 10px;
    border-bottom: 3px solid $white;
    font-size: 2.5em;
    text-transform: uppercase;
    color: $white;
    z-index: 2;
  }

  &__footer-menu-wrapper {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__contact-us-button {
    height: 48px;
    padding-right: 20px;
    padding-left: 20px;
    color: $white;
    background-color: $dark-green;
    border-radius: $layout-border;
    &:hover {
      background-color: transparent;
      color: $dark-green;
    }
  }
}
