.r4z-page-slide {
  &__section {
    display: block;
    height: 100%;
  }
  &__col {
    &--1 {
      width: 100%;
    }
    &--2 {
      width: 50%;
    }
    &--3 {
      width: 33.33333333%;
    }
    &--4 {
      width: 25%;
    }
    &--5 {
      width: 20%;
    }
    &--6 {
      width: 16.66666666%;
    }
  }
}
