@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-mobile-section {
  &__wrapper {
    padding: 40px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 10vw;
  }

  &__title-wrapper {
    padding: 2vw 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    padding: 2vw 20px;
    font-size: 5vw;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3em;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    &--white {
      color: $white;
      border-bottom-color: $white;
    }
  }

  &__text-wrapper {
    padding: 2vw 20vw;
  }

  &__text {
    padding: 0 10px;
    text-align: center;
    line-height: 1.2em;
    font-size: 4vw;
    @include tablet-small {
      font-size: 3vw;
    }
    &--white {
      color: $white
    }
  }
}
