@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-top-menu {
  display: none;
  flex: 1;
  height: 100%;
  overflow: hidden;
  transition: visibility ease-out 0.6s;
  position: relative;
  @include tablet-medium {
    display: flex;
  }

  &--white {
    color: $white;

    a {
      color: $white;

      &:hover {
        font-weight: 700;
      }

      &:after {
        background: $white;
      }
    }
  }

  &--black {
    color: $black;

    a {
      color: $black;

      &:hover {
        font-weight: 600;
      }

      &:after {
        background: $black;
      }

    }
  }

  &__body {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: transform ease-out 0.4s;

    &--hidden {
      transform: translateY(-100%);
    }
  }

  &__link-wrapper {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 105px;
    transform: skew(0deg);
    color: inherit;

    a {
      text-transform: uppercase;
      font-size: 0.7em;
      font-weight: 400;
      @include desktop {
        font-size: 0.8em;
      }
      @include desktop-large {
        font-size: 0.9em;
      }

      &:hover {
        color: inherit;
        cursor: pointer;

        &:after {
          width: 65%;
        }
      }

      &:active {
        font-size: 1em;
      }

      &:after {
        content: '';
        width: 0;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 30%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition-timing-function: cubic-bezier(1, -0.65, 0, 2.31);
        transition-timing-function: cubic-bezier(1, -0.65, 0, 2.31);
        -webkit-transition: width 0.4s;
        transition: width 0.4s;
      }
    }
  }

  &__link {
    &--active {
      font-size: 17px;

      &:after {
        width: 70% !important;
      }
    }
  }

  &__get-started-button-wrapper {
    margin-top: -5px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-family: sans-serif;
  }

  &__get-start-button {
    color: $white;
    border-color: $white;
    background-color: transparent;

    &:hover {
      background-color: white;
      color: $dark-green;
    }
  }

  &__menu-icon-wrapper {
    height: 100%;
    padding-right: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
