@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-3-slide-content-mobile {
  background-color: #EAEAEA;
  padding-bottom: 40px;

  &__page-title-wrapper {
    position: relative;
  }

  &__page-title {
    font-size: 6vw;
    text-align: center;
    text-transform: uppercase;
    color: $dark-green;
  }

  &__page-title-after {
    margin-top: 10px;
    height: 60px;
    width: calc(50% - 2px);
    border-right: 4px solid $dark-green;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    max-width: 60%;
  }

  .r4z-3-slide-content-mobile-content {
    &__section-wrapper {
      margin-top: 30px;
      padding: 0 10px;
      display: flex;
    }

    &__section-title-wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    &__section-title {
      color: $dark-green;
      text-transform: uppercase;
      font-size: 8vw;
    }

    &__section-text-wrapper {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    &__section-text-title {
      text-transform: uppercase;
      font-size: 5vw;
      color: $light-black;
    }

    &__section-text {
      margin-top: 10px;
      color: $grey;
    }
  }
}