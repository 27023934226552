@import "../../../assets/styles/breakpoints";

.r4z-action-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    padding: 10px 20%;
    font-size: 6vw;
    text-align: center;
    @include tablet-small {
      padding: 10px 0;
      font-size: 1.8em;
    }
  }

  &__text {
    max-width: 400px;
    text-align: center;
    padding: 10px 0;
  }

  &__button-wrapper {
    padding: 20px 0;
  }
}