.r4z-side-slider {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 1030;
    transition: visibility 0.5s linear, background-color 0.5s ease;

    &--open {
      visibility: visible;
      background-color: rgba(0, 0, 0, .65);
    }

    &--closed {
      visibility: hidden;
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    background-color: #ffffff;
    max-width: 504px;
    transform: translateX(0px);
    transition: transform .5s ease;
    z-index: 1032;

    &--right {
      right: 0;
      &.closed {
        transform: translateX(+100%);
      }
    }

    &--left {
      left: 0;
      &.closed {
        transform: translateX(-100%);
      }
    }
  }

  &__content {
    padding-top: 50px;
    overflow: hidden;
  }

  &__close-button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 3%;
    font-size: 3.4rem;
    font-family: sans-serif;
    cursor: pointer;
    color: #6e6e6e;
  }
}
