@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-footer-menu {
  display: flex;
  flex-direction: column;
  line-height: 1.5em;
  font-size: 0.7em;
  font-weight: 700;
  @include mobile {
    font-size: 0.9em;
  }
  @include tablet-small {
    font-size: 1.2em;
    line-height: 1.1em;
  }
  &__links-wrapper {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
  }
  &__item {
    margin-left: 10px;
    color: $dark-green;

    p, a {
      padding: 0 2px;
      border-bottom: 1px solid transparent;
    }

    &--right-pipe {
      border-right: 2px solid $dark-green;
      padding-right: 5px;
    }
    &--capitalize {
      p {
        text-transform: capitalize;
      }
    }
    &:hover {
      p, a {
        border-bottom: 1px solid $dark-green;
      }
    }
  }
  &__clickable-text {
    cursor: pointer;
  }
  &__legal-text {
    font-weight: 400;
    color: $dark-green;
    text-align: center;
  }
}
