@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-mobile-section-title {
  padding: 40px 0;

  &__text {
    font-size: 6vw;
    text-align: center;
    text-transform: uppercase;
  }

  &__vertical-line {
    margin-top: 10px;
    height: 60px;
    width: calc(50% - 2px);
    border-right-width: 4px;
    border-right-style: solid;
  }
}