@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/variables";

.r4z-content-section-component {
  min-height: 70vh;

  &--white {
    background-color: $white;
  }

  &--grey {
    background-color: #EAEAEA;
  }

  &__main-title-wrapper {
    padding: 40px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__main-title {
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
    color: $green;
    @include tablet-medium {
      font-size: 2.5em;
    }
  }

  &__main-title-image {
    width: 90%;
    max-width: 400px;
    height: auto;
  }

  &__page-title-after {
    margin: 15px 0;
    height: 50px;
    width: calc(50% - 2px);
    border-right: 4px solid $dark-green;
  }

  &__icon-wrapper {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 20vw;
    @include tablet-medium {
      width: 80px;
    }
  }

  &__secondary-title-wrapper {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__secondary-title {
    padding-bottom: 20px;
    border-bottom: 1px solid $black;
    max-width: 800px;
    text-align: center;
    line-height: 4rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    @include tablet-medium {
      font-size: 4em;
    }
  }

  &__paragraph-wrapper {
    margin: auto;
    padding-bottom: 6rem;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__paragraph {
    padding: 10px;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    @include tablet-small {
      font-size: 1.3em;
    }
    @include tablet-medium {
      font-size: 1.5em;
    }
  }
}
