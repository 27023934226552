@import "../../../assets/styles/breakpoints";

.rfz-menu-button {
  position: relative;
  display: block;
  width: 32px;
  height: 36px;
  cursor: pointer;

  &__icon-wrap {
    position: absolute;
    display: block;

    height: 4px;
    top: 50%;
    right: 0;
    transition: all .3s ease;
    transform-origin: 100% 50%;
    border-radius: 5px;
    width: 25px;
    @include tablet-small {
      width: 28px;
    }
    @include tablet-medium {
      width: 30px;
    }

    &--white {
      background: #ffffff;
      color: white;
    }

    &--black {
      background: #000000;
      color: #000000;
    }
  }

  &--top-line {
    margin-top: -9px;
  }

  &--middle-line {
    margin-right: 0;
    width: 20px;
    @include tablet-small {
      width: 23px;
    }
    @include tablet-medium {
      width: 25px;
    }
  }

  &--bottom-line {
    margin-top: 9px;
  }
}
