@import "../../../assets/styles/variables";

.r4z-calc-start-page {
  &__wrapper {
    background-color: #f2f2f2;
    background-image: url("../../../assets/img/calculator_background.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

  &__body {
    min-height: calc(100vh - 210px);
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
    text-align: center;

    &--color {
      text-transform: capitalize;
      color: #42C3BC;
    }
  }

  &__title-text {
    margin: 2rem 0 0;
    line-height: 1.5;
    font-size: 1.5rem;
    &--active {
      font-weight: 700;
      text-transform: capitalize;
      color: #B2DE31;
    }
  }

  &__start-button {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto 0;
    padding: 0.5rem 1.8rem;
    border: 1px solid #33e0db;
    background-color: #33e0db;
    color: white;
    font-size: 1.3rem;
    text-align: center;
    text-decoration: none;
    border-radius: 9px;
    transition: color 0.15s ease, border-color 0.15s ease;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: #2e8780;
      background-color: unset;
      border: 1px solid #42C3BC;
    }

    &:disabled {
      background-color:  #2E8780FF;
      border: 1px solid #2E8780FF;
      cursor: auto;

      &:hover {
        color: $white;
        background-color:  #2E8780FF;
      }

      &:active {
        transform: scale(1);
      }
    }
  }

  &__footer-wrapper {
    max-width: 900px;
    margin: auto;
  }

  &__consent-text {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.1rem;
    a {
      color: #42C3BC;
    }
  }
}

.r4z-calc-footer {
  width: 100%;
  height: 80px;
  display: flex;
  flex: 1;
  border-top: 1px solid #6d6d6d;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 600;
    &--with-color {
      text-transform: capitalize;
      color: #42C3BC;
    }
  }

  &__logo {
    img {
      width: 50px;
      margin-left: 20px;
    }
  }

}
