@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-1-slide-content {
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  background-image: url("../../../../../../assets/img/6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  &__wrapper {
    max-width: 1200px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow: hidden;
    @include desktop {
      width: 95%;
    }
  }

  &__title {
    margin: 0 0 10px 0;
    color: white;
    line-height: 1.1em;
    text-transform: uppercase;
    text-shadow: 0 0 white;
    font-weight: 600;
    animation: fadeIn 1400ms ease-in-out, slideInLeft 1100ms cubic-bezier(0, 1, 0.2, 1);
    font-size: 0.9em;
    @include mobile {
      font-size: 1.5em;
    }
    @include tablet-small {
      font-size: 1.7em;
    }
    @include tablet-medium {
      font-size: 2.3em;
    }
    @include desktop {
      font-size: 3em;
    }
    @include desktop-large {
      font-size: 3.75em;
    }
  }

  &__text {
    margin-top: 30px;
    color: white;
    animation: fadeIn 1400ms ease-in-out, slideInLeft 2100ms cubic-bezier(0, 1, 0.2, 1);
    font-size: 0.85em;
    @include mobile {
      font-size: 1em;
    }
    @include tablet-small {
      font-size: 1em;
    }
    @include tablet-medium {
      font-size: 1.3em;
    }
    @include desktop {
      font-size: 1.5em;
    }
    @include desktop-large {
      font-size: 1.8em;
    }
  }
}
