@import "../../../assets/styles/variables";
@import "../../../assets/styles/breakpoints";

.r4z-header {
  z-index: 99;
  overflow: hidden;
  transition: height ease-out 0.3s;

  &--sticky {
    width: 100%;
    height: 70px;
    padding-right: 20px;
    position: fixed;
    top: 0;

    .r4z-header {
      &__menu-wrapper {
        padding-right: 13px;
      }
    }

    @include desktop {
      height: 80px;
    }
  }

  &--scroll {
    width: 100%;
    height: 70px;
    @include desktop {
      height: 80px;
    }
  }

  &--mobile {
    height: 50px;
    font-size: 14px;
  }

  &--shrink {
    height: 60px;

    & .r4z-header {
      &__logo-text {
        font-size: 1.4em;

        &--white {
          color: $white;
        }

        &--black {
          color: $black;
        }
      }
    }
  }

  &--full {
    height: 80px;
  }

  &__inner-wrapper {
    height: 100%;
    flex: 1;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 97%;
    @include desktop {
      max-width: 85%;
    }
    @include desktop-large {
      max-width: 81%;
    }
  }

  &__logo-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-image {
    width: 100%;
    max-width: 40px;
    @include desktop {
      max-width: 52px;
    }
  }

  &__logo-text {
    font-family: $logo-font;
    padding-left: 10px;
    //text-transform: uppercase;

    &--white {
      color: $white;
    }

    &--black {
      color: $black;
    }
  }

  &__menu-wrapper {
    display: flex;
    flex: 1;
    height: 100%;
  }
}

