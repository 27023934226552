@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/breakpoints";

.r4z-3-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-black;
  position: relative;
  overflow: hidden;
}

.r4z-3-slide-content-left {
  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title-wrapper {
    padding: 30px 0;
  }

  &__title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    max-width: 70%;
  }

  &__icon-wrapper {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    max-width: 60%;
  }
}

.r4z-3-slide-content-right {
  &__wrapper {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../../assets/img/r4z-vision-banner.png");
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__section-wrapper {
    width: 100%;
    min-height: 8vh;
    margin-top: 2.5rem;
    padding: 0 2%;
    display: flex;
    color: $white;
  }

  &__section-title-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__section-title {
    text-transform: uppercase;
    @include desktop {
      font-size: 2rem;
    }
    @include desktop-large {
      font-size: 2.5rem;
    }
  }

  &__section-text-wrapper {
    flex: 2;
    margin-left: 0;
    padding-right: 6%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__section-text-title {
    text-transform: uppercase;
    font-size: 2rem;
  }

  &__section-text {
    width: 80%;
    margin-top: 15px;
    font-weight: 600;
    @include tablet-medium {
      font-size: 1rem;
    }
    @include desktop {
      font-size: 1.2rem;
    }
    @include desktop-large {
      font-size: 1.4rem;
    }
  }

  &__icon-wrapper {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 60%;
  }
}

.r4z-3-slide-content.active {
  animation: fadeIn 1500ms ease-in;
}
