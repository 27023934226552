@import "../../../assets/styles/variables";

.r4z-input {
  width: 100%;
  padding-left: 0.4em;
  border: 1px solid $grey-transparent;
  border-radius: 5px;
  box-sizing: border-box;
  color: $grey-light;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: inherit;
  letter-spacing: 0.4px;

  &--withIcon {
    padding-left: 2.5em;
  }

  &:active {
    color: $grey-dark;
  }

  &:focus {
    border: 2px solid $dark-green-dimmed;
    outline: 0;
  }

  &__wrapper {
    position: relative;
  }

  &--invalid {
    border: 2px solid $red-light;
    color: $red-light;
  }

  &__icon-wrapper {
    width: 17px;
    height: 19px;
    position: absolute;
    top: 46%;
    left: 15px;
    transform: translateY(-50%);
  }

  &__icon-image {
    width: 100%;
    height: 100%;
  }

  &__label {
    color: $lighter-black;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 1.1em;

    &--invalid {
      color: $red-light;
    }

    &--required:after {
      content: '*';
      display: inline;
      padding-left: 0.1em;
    }

  }

  &__error-wrapper {
    padding: 6px 0 0 5px;
    color: $red-light;
  }
}
