@import "../../../assets/styles/breakpoints";

.component {
  height: 100vh;

  &--overlay {
    &:before {
      background: rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
}

//div.bg {
//  background-position: center -219px;
//  background-color: rgba(0, 0, 0, 0.2);
//  background-image: url("../../assets/img/6.jpg");
//  background-attachment: fixed;
//  background-repeat: no-repeat;
//  background-size: cover;
//  filter: grayscale(20%);
//}

.r4z-slide-content {
  &__scroll-down-button-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }
}

.active {
  .r4z-slide-content {
    &__scroll-down-button-wrapper {
      animation: fadeInSlowIn 4000ms ease-in-out;
    }
  }
}

.section-1 {
  height: 100%;
  position: relative;
}

.section-2 {
  position: relative;
}

.section-6 {
  position: relative;
}

.r4z-mobile-section {
  &__footer-wrapper {
    padding: 20px 0;
  }
}

.r4z-home-page {
  &__footer-menu {
    padding: 40px 0;
    @include tablet-medium {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1200px;
      padding-bottom: 20px;
    }
  }
}


@keyframes bounce {
  20%, 50%, 80%, to {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  70% {
    transform: translateY(-5px);
  }
}
