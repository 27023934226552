@import "../../../assets/styles/variables";

.r4z-contact-modal {
  &__container {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text {
    color: $light-black;
  }

  &__image {
    width: 100%;
    max-width: 200px;
    margin-top: 20px;
    transition: transform ease-in-out 400ms;
    &:hover {
      transform: scale(1.25);
    }
  }
}